import styled from '@emotion/styled';
import { readableColorIsBlack } from 'color2k';
import { StrokeText } from '~/shared/components';
import { cover } from '~/shared/utils/styled';
import { breakpoints, GeneratedThemeShade } from '~/theme';
import { Headline } from '../Headline/Headline';

export const StyledM22 = styled.div(({ theme }) => ({
    width: '100%',
    margin: '0 auto',
    [breakpoints.md]: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: `repeat(${theme.gridConfig.columnCount.md}, 1fr)`,
    },
}));

export const StyledM22Content = styled.div({
    [breakpoints.md]: {
        gridColumn: '2 / -2',
    },
});

export const StyledM22CategoriesHeader = styled(Headline)<{
    titleAlignment: 'left' | 'center' | 'right';
}>(({ theme, titleAlignment }) => ({
    marginBottom: theme.spaces[4],
    textAlign: titleAlignment,
}));

export const StyledM22Categories = styled.div<{ itemsPerLine: number }>(
    ({ theme, itemsPerLine }) => ({
        display: 'grid',
        gap: theme.spaces[4],

        [breakpoints.sm]: {
            gridTemplateColumns: '1fr 1fr',
        },

        [breakpoints.md]: {
            gridTemplateColumns: `repeat(${Math.max(2, itemsPerLine % 4)}, 1fr)`,
        },

        [breakpoints.lg]: {
            gridTemplateColumns: `repeat(${itemsPerLine}, 1fr)`,
        },
    })
);

export const StyledM22Category = styled.div<Pick<GeneratedThemeShade, 'backgroundColor'>>(
    ({ theme: { spaces, colors, borders }, backgroundColor }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: spaces[2],
        position: 'relative',
        padding: spaces[4],
        border: borders.border,
        minWidth: 0, // Makes sure items in grid are not overflowing
        borderColor: readableColorIsBlack(backgroundColor || colors.light)
            ? colors.light40
            : colors.dark50,
    })
);

export const StyledM22TextContentWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces[1],
    zIndex: 1,
}));

export const StyledM22StrokeText = styled(StrokeText)<Pick<GeneratedThemeShade, 'backgroundColor'>>(
    ({ theme: { colors }, backgroundColor }) => {
        if (backgroundColor === colors.light30.toLowerCase()) {
            return {
                color: colors.light40,
            };
        }

        return {
            color: readableColorIsBlack(backgroundColor || colors.light)
                ? colors.light30
                : colors.dark70,
        };
    }
);

export const StyledM22Link = styled.a(({ theme }) => ({
    textDecoration: 'none',
    display: 'flex',
    gap: theme.spaces[4],
    justifyContent: 'space-between',
    wordBreak: 'break-word',

    svg: {
        flexShrink: 0,
    },

    ':before': {
        content: '""',
        ...cover,
    },
}));
