import React, { memo } from 'react';
import { M22CategoriesModule } from '~/lib/data-contract';
import { RouterLink, Text } from '~/shared/components';
import { ConditionalWrap } from '~/shared/utils/jsx';
import { ModuleContainer } from '~/templates/blocks/components/ModuleContainer';
import { itemMapper } from '~/templates/blocks/utils/itemMapper';
import Arrow from '$icons/arrow-right.svg';
import { useThemeShade } from '~/theme';
import {
    StyledM22,
    StyledM22Content,
    StyledM22Categories,
    StyledM22Category,
    StyledM22CategoriesHeader,
    StyledM22StrokeText,
    StyledM22Link,
    StyledM22TextContentWrapper,
} from './styled';

export type M22CategoriesProps = M22CategoriesModule;

export type M22ImageAspectRatio = { w: number; h: number; wDesktop: number; hDesktop: number };

/**
 * List of category and/or page links. Can also be used to display information without links.
 *
 */
export const M22Categories = memo(
    ({
        items,
        headline,
        headlineAlignment = 'left',
        backgroundColor,
        ...rest
    }: M22CategoriesProps) => {
        const categories = itemMapper(items);
        const categoriesLength = categories?.length ?? 0;
        const maxPerLine = categoriesLength % 3 ? 4 : 3;
        const columnCount = Math.min(categoriesLength, maxPerLine);
        const { backgroundColor: generatedBgColor, headlineShade, textShade } = useThemeShade({
            backgroundColor,
        });

        return (
            <ModuleContainer hasGutter backgroundColor={backgroundColor} {...rest}>
                <StyledM22>
                    <StyledM22Content>
                        {headline ? (
                            <StyledM22CategoriesHeader
                                variant="display2"
                                titleAlignment={headlineAlignment}
                                shade={headlineShade}
                            >
                                {headline}
                            </StyledM22CategoriesHeader>
                        ) : null}
                        <StyledM22Categories itemsPerLine={columnCount}>
                            {categories?.map(
                                ({ callToAction, headline, description: text }, index) => (
                                    <StyledM22Category
                                        backgroundColor={generatedBgColor}
                                        key={index}
                                        data-stroketext-trigger
                                    >
                                        <StyledM22TextContentWrapper>
                                            <Text variant="display4" shade={headlineShade}>
                                                <ConditionalWrap
                                                    if={Boolean(callToAction?.url)}
                                                    wrap={(children) => (
                                                        <RouterLink
                                                            {...callToAction}
                                                            href={callToAction?.url || ''}
                                                        >
                                                            <StyledM22Link {...callToAction}>
                                                                {children}
                                                                <Arrow />
                                                            </StyledM22Link>
                                                        </RouterLink>
                                                    )}
                                                >
                                                    {headline || callToAction?.title}
                                                </ConditionalWrap>
                                            </Text>
                                            <Text variant="bodySm" shade={textShade}>
                                                {text}
                                            </Text>
                                        </StyledM22TextContentWrapper>
                                        {callToAction?.url && (
                                            <StyledM22StrokeText
                                                backgroundColor={generatedBgColor}
                                                strokeHeight="100%"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </StyledM22Category>
                                )
                            )}
                        </StyledM22Categories>
                    </StyledM22Content>
                </StyledM22>
            </ModuleContainer>
        );
    }
);
